import axios from 'axios'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { baseUrl } from './baseurl'
import { Floor } from './types/floor'
import dayjs from 'dayjs'
import { AttendanceUtils } from '@/utils/attendance'

const url = `${baseUrl}/floors`

export const useFloorsStore = defineStore('floors', () => {
  let buildingId: string = ''
  const floors: Ref<Array<Floor>> = ref([])
  const newFloor: Ref<Floor | undefined> = ref()
  const selected: Ref<Floor | undefined> = ref()
  const roomsSortedBy: Ref<'shortname' | 'index'> = ref('shortname')

  function sortRooms() {
    if (!selected.value?.rooms) return
    selected.value.rooms.sort((a, b) =>
      roomsSortedBy.value === 'index'
        ? a.xPosition * 10 + a.yPosition - (b.xPosition * 10 + b.yPosition)
        : a.shortName.localeCompare(b.shortName)
    )
  }

  async function findByBuildingId(pBuildingId: string) {
    try {
      // do not load floors if already loaded
      if (buildingId == pBuildingId) {
        return
      }
      selected.value = undefined
      const data = await axios.get(`${url}?buildingId=${pBuildingId}`)
      floors.value = data.data
      floors.value
        .sort((a, b) => (a.isArchived && !b.isArchived ? 1 : -1))
        .sort((a: Floor, b: Floor) => a.index - b.index)
      buildingId = pBuildingId
    } catch (error) {
      console.log(error)
    }
  }

  async function load(floorId: string): Promise<Floor | undefined> {
    try {
      // do not load selected floor if already loaded
      if (selected.value?.id == floorId) {
        return selected.value
      }
      selected.value = undefined
      const data = await axios.get(`${url}/${floorId}`)
      selected.value = data.data as Floor
      // sort rooms per index or shortname
      if (selected.value.rooms) {
        selected.value.rooms = selected.value.rooms.filter((x) => !x.isArchived)
        selected.value.rooms.sort((a, b) =>
          roomsSortedBy.value === 'index'
            ? a.index - b.index
            : a.shortName.localeCompare(b.shortName)
        )
      }
      return selected.value
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status == 404) {
        console.error(`Floor with id ${floorId} not found`, error)
      } else {
        console.log(error)
      }
    }
  }

  async function loadWithAttendanceDate(
    floorId: string,
    attendanceDate: dayjs.Dayjs | undefined
  ): Promise<Floor> {
    try {
      if (attendanceDate === undefined) throw new Error('No attendance date provided')

      selected.value = undefined
      const data = await axios.get(
        `${url}/${floorId}/attendance-week?attendanceDate=${attendanceDate.format('YYYY-MM-DD')}`
      )
      const floor = data.data as Floor
      // update stats
      floor.rooms
        .sort((a, b) =>
          roomsSortedBy.value === 'index'
            ? a.index - b.index
            : a.shortName.localeCompare(b.shortName)
        )
        .forEach((room) => {
          room.students.forEach((student) => {
            AttendanceUtils.updateStats(student)
          })
        })

      selected.value = floor
      return floor
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status == 404) {
        console.error(`Floor with id ${floorId} not found`, error)
      } else {
        console.log(error)
      }
      throw error
    }
  }

  async function downloadFloorPlanPdf(
    floorId: string,
    includeStudentNames: boolean,
    buildingShortname: string | undefined,
    floorShortname: string | undefined
  ) {
    const response = await axios.get(
      `${url}/${floorId}/plan${includeStudentNames ? '?includeStudentNames=true' : ''}`,
      { responseType: 'arraybuffer' }
    )
    const blob = new Blob([response.data], { type: 'application/pdf' })

    // create file link in browser's memory
    const href = URL.createObjectURL(blob)

    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = href
    link.download = `Plan Bâtiment ${buildingShortname} - étage ${floorShortname}.pdf`
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    setTimeout(() => {
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }, 10000)
  }

  async function addNew(buildingId: string): Promise<Floor | null> {
    try {
      newFloor.value = new Floor(buildingId)
      const floorCreatedResp = await axios.post(`${url}`, newFloor.value)
      floors.value = floors.value.concat(floorCreatedResp.data)
      return floorCreatedResp.data
    } catch (error) {
      console.log(error)
      return null
    }
  }

  function resetNew(buildingId: string) {
    newFloor.value = new Floor(buildingId)
  }

  async function update(floor: Floor) {
    try {
      const data = await axios.put(`${url}/${floor.id}`, floor)
      floor = data.data as Floor
      floors.value = floors.value.map((x) => (x.id == floor.id ? floor : x))
    } catch (error) {
      console.log(error)
    }
  }

  async function archive(floor: Floor) {
    try {
      floor.isArchived = true
      await update(floor)
    } catch (error) {
      console.log(error)
    }
  }

  async function unarchive(floor: Floor) {
    try {
      floor.isArchived = false
      await update(floor)
    } catch (error) {
      console.log(error)
    }
  }

  async function remove(floor: Floor) {
    try {
      await axios.delete(`${url}/${floor.id}`)
      floors.value = floors.value.filter((x) => x.id != floor.id)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    floors,
    newFloor,
    selected,
    roomsSortedBy,
    findByBuildingId,
    load,
    sortRooms,
    loadWithAttendanceDate,
    downloadFloorPlanPdf,
    addNew,
    resetNew,
    update,
    archive,
    unarchive,
    remove
  }
})
