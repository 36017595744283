import type { Attendance } from './attendance'
import type { Loan } from './loan'
import type { PreviousRoom } from './previousroom'

export class Student {
  id: string = ''
  isArchived: boolean = false
  createdAt: Date = new Date()
  updatedAt: Date = new Date()
  firstname: string = ''
  lastname: string = ''
  gender: string = ''
  phoneNumber: string = ''
  email: string = ''
  picture: string = ''
  classname: string = '2nde'
  level: number = 0
  isLikeLevel0: boolean = false
  isPap: boolean = false
  isPai: boolean = false
  isPps: boolean = false
  comment: string = ''
  roomRef: string | null = null
  isRoomReferent: boolean = false

  loans: Array<Loan> = []

  // isPairMaster -> Maitre au Pair
  isPairMaster: boolean = false

  // Parents
  parent1PhoneNumber: string = ''
  parent2PhoneNumber: string = ''
  otherContactPhoneNumber: string = ''

  attendances: Array<Attendance> = []

  previousRooms: Array<PreviousRoom> = []

  // stats
  nbStudies: number = 0
  nbAbsences: number = 0
  nbPresent: number = 0
  nbEvening: number = 0
  nbAuthorizedToLeave: number = 0
  nbTotalPossibleStudies: number = 0
}
