<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref, type PropType, type Ref } from 'vue';
import { baseUrl } from '@/stores/baseurl';
import type { ChangelogItem } from '@/stores/types/changelogitem';
import { symbolName } from 'typescript';

const prop = defineProps({
  forceDisplay: { type: Boolean as PropType<Boolean>, default: false },
})

const emits = defineEmits(["forceUpdate"])

const version = ref(__APP_VERSION__)

const newVersion = ref("")
const changelog: Ref<Map<string, ChangelogItem>> = ref(new Map())
const changelogOrdered: Ref<Array<{ semver: string, value: ChangelogItem }>> = ref(new Array())
const currentVersionChangelog: Ref<ChangelogItem | undefined> = ref()
const currentVersionChangelogOrderedIndex: Ref<number> = ref(0)

const dialogDisplayed = ref(false)

onMounted(async () => {
  // Check if version is aligned, otherwise reload and clear cache (using timestamp in URL)
  // Do it every hour !
  checkVersion()
  setInterval(() => {
    // every hour we can retry an update (to prevent never updating anymore...)
    sessionStorage.setItem("updateCount", "0")
    checkVersion()
  }, 60 * 60 * 1000)
})

async function checkVersion() {
  // query URL without using browser cache
  const headersNoCache = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  }
  const versionResp = await axios.get(`${baseUrl}/version`, {
    headers: headersNoCache,
  })
  const buildVersion = versionResp.data.version
  if (buildVersion !== version.value || prop.forceDisplay) {
    newVersion.value = buildVersion

    // Load changelog to display what's new in this version
    const changelogResp = await axios.get(`${baseUrl}/changelog`, {
      headers: headersNoCache,
    })
    changelog.value = new Map(Object.entries(changelogResp.data))
    changelog.value.forEach((value, key) => {
      changelogOrdered.value.push({ semver: key, value: value })
    })

    if (version.value == "0.0.0") {
      newVersion.value = changelog.value.keys().next().value ?? ""
    }
    currentVersionChangelog.value = changelog.value.get(newVersion.value)
    currentVersionChangelogOrderedIndex.value = changelogOrdered.value.findIndex(x => x.semver === newVersion.value)

    // Display dialog if it's not a silent update or if dialog is forced to be displayed
    dialogDisplayed.value = Boolean(prop.forceDisplay || currentVersionChangelog.value?.isSilent !== true)

    // in case it's a silent update, immediatly update
    if (prop.forceDisplay !== true && currentVersionChangelog.value?.isSilent === true) {
      // do not loop infinitly
      let updateCount = parseInt(sessionStorage.getItem("updateCount") ?? "0", 10)
      updateCount++
      if (updateCount > 3) {
        console.error("Infinite update -> STOP !")
      } else {
        sessionStorage.setItem("updateCount", String(updateCount))
        emits('forceUpdate')
      }
    }
  }
  // once versions are aligned, reset updateCount !
  if (buildVersion === version.value) {
    // on success reset updateCount
    sessionStorage.setItem("updateCount", "0")
  }
}

function containsPreviousVersion() {
  return currentVersionChangelogOrderedIndex.value < changelogOrdered.value.length - 1
}

function previousVersion() {
  if (containsPreviousVersion()) {
    currentVersionChangelogOrderedIndex.value++
    changedVersion()
  }
}

function containsNextVersion() {
  return currentVersionChangelogOrderedIndex.value > 0
}

function nextVersion() {
  if (containsNextVersion()) {
    currentVersionChangelogOrderedIndex.value--
    changedVersion()
  }
}

function changedVersion() {
  newVersion.value = changelogOrdered.value[currentVersionChangelogOrderedIndex.value].semver
  currentVersionChangelog.value = changelog.value.get(newVersion.value)
}

</script>
<template>
  <v-dialog v-model="dialogDisplayed" persistent>
    <v-row justify="center">
      <v-card style="width: min(500px, 80%); height: 400px;">
        <v-card-title>
          <div v-if="forceDisplay">Version <b>{{ newVersion }}</b></div>
          <div v-if="!forceDisplay">
            <v-icon color="primary" class="mr-1">mdi-information</v-icon>
            Nouvelle version <b>{{ newVersion }}</b>
          </div>
        </v-card-title>
        <v-col>
          <v-card-item style="display: inline-block; height: 272px; width: 100%; overflow: scroll;">
            <div class="mb-4"
              v-if="currentVersionChangelog?.features?.length && currentVersionChangelog?.features.length > 0">
              <v-divider class="mb-2"></v-divider>
              <v-card-subtitle>Nouveautés</v-card-subtitle>
              <v-row v-for="(item, i) in currentVersionChangelog?.features" :key="i" :value="item" no-gutters
                justify="start">
                <div class="mr-1"><v-icon color="success">{{ item.icon }}</v-icon></div>
                <div style="width: calc(100% - 28px);">{{ item.text }}</div>
              </v-row>
            </div>
            <!-- Bug fixes -->
            <div v-if="currentVersionChangelog?.fixes.length && currentVersionChangelog?.fixes.length > 0">
              <v-divider class="mb-2"></v-divider>
              <v-card-subtitle>Correctifs</v-card-subtitle>
              <v-row v-for="(item, i) in currentVersionChangelog?.fixes" :key="i" :value="item" no-gutters>
                <div class="mr-1"><v-icon color="error">{{ item.icon }}</v-icon></div>
                <div style="width: calc(100% - 28px);">{{ item.text }}</div>
              </v-row>
            </div>
          </v-card-item>
          <v-card-actions>
            <v-col style="margin: 0; padding: 0;">
              <v-row no-gutters justify="center" style="width: 100%;">
                <v-btn class="mx-1" icon="mdi-arrow-left-drop-circle" variant="text" :disabled="!containsNextVersion()"
                  @click="nextVersion()"></v-btn>
                <v-btn class="mx-1" icon="mdi-arrow-right-drop-circle" variant="text"
                  :disabled="!containsPreviousVersion()" @click="previousVersion()"></v-btn>
              </v-row>
              <v-row no-gutters style="margin-top: -48px; height: 48px;" align="center">
                <v-spacer></v-spacer>
                <v-btn @click="$emit('forceUpdate')">Ok</v-btn>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-col>
      </v-card>
    </v-row>
  </v-dialog>
</template>