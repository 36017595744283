<script setup lang="ts">
import { onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useCommentsStore } from '@/stores/comments'
import { useProfileStore } from '@/stores/profile'
import dayjs from 'dayjs'
import CommentItem from '@/components/CommentItem.vue'
import FloorStats from '@/components/FloorStats.vue'
import AttendanceFloorView from '@/components/AttendanceFloor.vue'
import ExportsView from '@/components/Exports.vue'
import DateDisplay from '@/components/DateDisplay.vue'
import FloorPlan from '@/components/FloorPlan.vue'
import LoansObjects from '@/components/LoansObjects.vue'
import RoomAdminItem from '@/components/RoomAdminItem.vue'
import { useFloorsStore } from '@/stores/floors'
import type { Room } from '@/stores/types/room'
import { useRoomsStore } from '@/stores/rooms'
import { useStudentsStore } from '@/stores/students'
import type { Student } from '@/stores/types/student'
import { getAttendanceDay } from '@/utils/dates'

const profileStore = useProfileStore()
const commentsStore = useCommentsStore()
const floorStore = useFloorsStore()
const roomStore = useRoomsStore()
const studentStore = useStudentsStore()

const route = useRoute()
const router = useRouter()

const buildingId = ref(route.params.buildingId as string)
const floorId = ref(route.params.floorId as string)
const tabId = ref(route.params.tabId as string || "comments")
const fromDateStr = ref(route.query.fromDateStr as string)
const toDateStr = ref(route.query.toDateStr as string)

const currentDay = ref()
const previousDay = ref()
const editRoom: Ref<Room | undefined> = ref()
const allStudents: Ref<Array<Student>> = ref([])

const tab = ref(tabId)

onMounted(async () => {
  commentsStore.building = undefined
  commentsStore.floor = undefined

  currentDay.value = dayjs(route.query.dateStr as string) || dayjs()
  // when arriving there with url or because it's today, 
  // - then when it's friday go to thursday,
  // - when it's sunday go to monday
  if (currentDay.value.day() == 5) {
    currentDay.value = currentDay.value.subtract(1, "day")
  } else if (currentDay.value.day() == 0) {
    currentDay.value = currentDay.value.add(1, "day")
  }
  // ensure provided day is and attendance day (between monday and thursday)
  currentDay.value = getAttendanceDay(currentDay.value)
  // Load building and comment
  await load(currentDay.value.format("YYYY-MM-DD"))
  allStudents.value = await studentStore.all()
});

const load = async function (dateStr: string = '') {
  await commentsStore.load(buildingId.value, floorId.value, dateStr)
  await floorStore.load(floorId.value)
}

const loadComment = async function (newDay: dayjs.Dayjs) {
  if (commentsStore.comment == null) return
  currentDay.value = newDay
  // console.debug(`Navigating to date ${currentDay.value.format("YYYY-MM-DD")}`)
  await load(currentDay.value.format("YYYY-MM-DD"))

  router.push({
    name: 'floor',
    params: {
      buildingId: commentsStore.building?.id,
      floorId: commentsStore.floor?.id,
      tabId: 'comments',
    },
    query: {
      dateStr: currentDay.value.format("YYYY-MM-DD")
    }
  })
}

const changedDay = async function (newDay: dayjs.Dayjs) {
  previousDay.value = currentDay.value
  currentDay.value = newDay
}

const changeDayAndLoadAttendance = async function (newDay: dayjs.Dayjs) {
  changedDay(newDay)

  router.push({
    name: 'floor',
    params: {
      buildingId: commentsStore.building?.id,
      floorId: commentsStore.floor?.id,
      tabId: 'attendance',
    },
    query: {
      dateStr: currentDay.value.format("YYYY-MM-DD")
    }
  })
}

const changeDayAndLoadComment = async function (newDay: dayjs.Dayjs) {
  changedDay(newDay)

  router.push({
    name: 'floor',
    params: {
      buildingId: commentsStore.building?.id,
      floorId: commentsStore.floor?.id,
      tabId: 'comments',
    },
    query: {
      dateStr: currentDay.value.format("YYYY-MM-DD")
    }
  })

  // Also update associated comment
  loadComment(newDay)
}

async function updateComment(commentTxt: string) {
  if (commentsStore.comment == null) return
  commentsStore.comment.comment = commentTxt
  await commentsStore.update(buildingId.value)
}

async function updateSignature(signature: string) {
  if (commentsStore.comment == null) return
  commentsStore.comment.signature = signature
  if (profileStore.current) {
    commentsStore.comment.workerRef = profileStore.current.id
  }
  await commentsStore.update(buildingId.value)
}

function tabChange() {
  if (!commentsStore.building || !commentsStore.floor) return
  router.push({
    name: 'floor',
    params: {
      buildingId: commentsStore.building?.id,
      floorId: commentsStore.floor?.id,
      tabId: tab.value,
    },
    query: route.query
  })
}

async function updateRoom(room: Room) {
  await roomStore.update(room)
  // also update locally
  if (floorStore.selected) {
    floorStore.selected.rooms = floorStore.selected.rooms.map(x => x.id === room.id ? room : x)
  }
}

async function printPlan(includeStudentNames: boolean) {
  await floorStore.downloadFloorPlanPdf(floorStore.selected!!.id, includeStudentNames, commentsStore.building?.shortName, floorStore.selected?.shortName)
}

function btnColor(room: Room): string {
  if (room.isWatcherRoom) {
    return 'red'
  } else if (room.isForReducedMobility) {
    return 'success'
  } else if (room.isPairMaster) {
    return 'yellow'
  } else if (room.nbStudents <= 0) {
    return 'grey'
  } else {
    return 'primary'
  }
}

async function addNewRoom() {
  const newRoom = await roomStore.addNew(floorId.value)
  if (newRoom == null) return
  editRoom.value = newRoom
  floorStore.selected?.rooms.push(newRoom)
}

function touchless() {
  return false
}
</script>

<template>
  <main>
    <v-col justify="center">
      <h1 class="text-center">Bâtiment {{ commentsStore.building?.shortName }}</h1>
      <h2 class="text-center">{{ commentsStore.floor?.shortName ?? " - " }}</h2>
    </v-col>

    <v-tabs align-tabs="center" v-model="tab" bg-color="secondary" :on-update="tabChange()">
      <v-tab value="comments">Observations</v-tab>
      <v-tab value="attendance">Appel</v-tab>
      <v-tab value="history">Historique</v-tab>
      <v-tab value="rooms">Chambres</v-tab>
      <v-tab value="loans">Prêts</v-tab>
    </v-tabs>

    <div class="bg-secondary">
      <v-container>
        <v-window v-model="tab" :touch="touchless()">
          <v-window-item value="comments">
            <h3 class="text-center mb-4">Observations</h3>
            <DateDisplay :currentDay="currentDay" @change="(newDay: dayjs.Dayjs) => changeDayAndLoadComment(newDay)">
            </DateDisplay>
            <CommentItem v-if="commentsStore.comment" editable :comment="commentsStore.comment"
              :current-day="currentDay" @update="(commentTxt: any) => updateComment(commentTxt)"
              @sign="(commentTxt: any) => updateSignature(commentTxt)">
            </CommentItem>
          </v-window-item>

          <v-window-item value="attendance">
            <h3 class="text-center mb-4">Appel</h3>
            <DateDisplay :currentDay="currentDay" @change="(newDay: dayjs.Dayjs) => changeDayAndLoadAttendance(newDay)">
            </DateDisplay>
            <AttendanceFloorView v-if="currentDay" :buildingId="buildingId" :floorId="floorId" :currentDay="currentDay"
              :previousDay="previousDay"></AttendanceFloorView>
          </v-window-item>

          <v-window-item value="history">
            <h3 class="text-center mb-4">Historique / Export</h3>
            <ExportsView :buildingId="buildingId" :floorId="floorId" :fromDateStr="fromDateStr" :toDateStr="toDateStr">
            </ExportsView>
          </v-window-item>

          <v-window-item value="rooms">
            <FloorStats :floorId="floorId"></FloorStats>
            <v-card>
              <v-card-title>
                <v-row no-gutters>
                  <h3 class="mb-4">Chambres des élèves</h3>
                  <v-spacer></v-spacer>
                  <v-btn v-if="profileStore.current?.role === 'admin'" variant="outlined" icon="mdi-pencil"
                    :to="'/admin/buildings/' + buildingId + '/floors/' + floorId + '/rooms'"></v-btn>
                </v-row>
              </v-card-title>
              <v-card-item>
                <v-row no-gutters justify="start" align-content="start">
                  <v-btn class="ma-2" :color="btnColor(room)" v-for="room in floorStore.selected?.rooms?.
                    filter(x => !x.isArchived && x.nbStudents > 0 && profileStore.current?.role !== 'admin')"
                    :key="room.id"
                    :to="'/buildings/' + buildingId + '/floors/' + floorId + '/rooms/details/' + room.id">
                    {{ room.shortName }}
                  </v-btn>
                  <v-btn class="ma-2" :color="btnColor(room)" v-for="room in floorStore.selected?.rooms?.
                    filter(x => !x.isArchived && x.nbStudents > 0 && profileStore.current?.role === 'admin')"
                    :key="room.id" @click="editRoom = room">
                    {{ room.shortName }}
                  </v-btn>
                </v-row>
              </v-card-item>

              <v-card-title v-if="profileStore.current?.role === 'admin'">
                <h3 class="mb-4">Autres pièces</h3>
              </v-card-title>
              <v-card-item v-if="profileStore.current?.role === 'admin'">
                <v-row no-gutters justify="start" align-content="start">
                  <v-btn class="ma-2" :color="btnColor(room)"
                    v-for="room in floorStore.selected?.rooms?.filter(x => !x.isArchived && x.nbStudents === 0).sort((a, b) => a.index - b.index)"
                    :key="room.id" @click="editRoom = room">
                    {{ room.shortName }}
                  </v-btn>
                </v-row>
              </v-card-item>
              <v-card-actions>
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-btn v-if="profileStore.current?.role === 'admin'" variant="elevated" @click="addNewRoom()"
                    icon="mdi-plus"></v-btn>
                </v-row>
              </v-card-actions>
            </v-card>

            <v-card v-if="editRoom" class="my-2">
              <v-card-title>
                <v-row no-gutters>
                  <v-icon color="primary" icon="mdi-shield"></v-icon>
                  <div class="mx-2">Modifier</div>
                  <v-spacer></v-spacer>
                  <v-btn icon="mdi-close" variant="outlined" @click="editRoom = undefined"></v-btn>
                </v-row>
              </v-card-title>
              <v-card-item>
                <RoomAdminItem :buildingId="buildingId" :room="editRoom" :allStudents="allStudents" archivable
                  @update="(room: Room) => updateRoom(room)"></RoomAdminItem>
              </v-card-item>
            </v-card>

            <FloorPlan v-if="floorStore.selected" class="my-2" :buildingId="buildingId"
              :floorName="floorStore.selected.shortName" :rooms="floorStore.selected.rooms.filter(x => !x.isArchived)"
              @roomUpdated="(room: Room) => updateRoom(room)"
              @print="(includeStudentNames: boolean) => printPlan(includeStudentNames)"></FloorPlan>
          </v-window-item>

          <v-window-item value="loans">
            <LoansObjects v-if="floorStore.selected" :floor="floorStore.selected"></LoansObjects>
          </v-window-item>
        </v-window>
      </v-container>
    </div>
  </main>
</template>
