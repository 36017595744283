import { createApp } from 'vue'
import { createPinia } from 'pinia'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import DayJsAdapter from '@date-io/dayjs'

// Recaptcha
import { VueRecaptchaPlugin } from 'vue-recaptcha/head'

// sentry
import * as Sentry from '@sentry/vue'

// Translations provided by Vuetify
import { fr } from 'vuetify/locale'

import { md2 } from 'vuetify/blueprints'

import App from './App.vue'
import router from './router'

// --- DAYJS config
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
dayjs.locale('fr')

import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)

import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)

// Themes
import { availableDarkThemes, availableLightThemes, defaultTheme } from './stores/types/themes'

// Create vuetify instance
const vuetify = createVuetify({
  components,
  directives,
  blueprint: md2,
  locale: {
    locale: 'fr',
    messages: { fr }
  },
  date: {
    adapter: DayJsAdapter,
    locale: {
      fr: dayjs.locale('fr')
    }
  },
  theme: {
    defaultTheme: defaultTheme,
    themes: {
      ...Object.fromEntries(availableLightThemes.entries()),
      ...Object.fromEntries(availableDarkThemes.entries())
    }
  }
})

const app = createApp(App)

app.use(vuetify)
app.use(createPinia())
app.use(router)
if (import.meta.env.VITE_RECAPTCHA_SITE_KEY_V3) {
  app.use(VueRecaptchaPlugin, {
    v3SiteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY_V3
  })
}

// Sentry configuration
Sentry.init({
  // Completely deactivate sentry for Dev
  enabled: process.env.NODE_ENV !== 'development',
  app,
  release: 'boarding_school@' + __APP_VERSION__,
  dsn: 'https://fb26455c8b86b5fc59708a8a3af09872@o4506990433796096.ingest.us.sentry.io/4506990438711296',
  autoSessionTracking: false
})

app.mount('#app')
