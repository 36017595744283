<script setup lang="ts">
import { useFloorsStore } from "@/stores/floors"
import { useRoomsStore } from "@/stores/rooms"
import type { Room } from "@/stores/types/room"
import { Student } from '@/stores/types/student'
import { gendersItems } from '@/stores/types/studentgenders'
import type { PropType, Ref } from "vue"
import { ref, onUpdated, onMounted } from "vue"

const props = defineProps({
  buildingId: { type: String, required: true },
  room: { type: Object as PropType<Room>, required: true },
  allStudents: { type: Object as PropType<Array<Student>>, required: true },
  archivable: { type: Boolean as PropType<Boolean>, default: true }
})
const emits = defineEmits(['update', 'remove'])

const valid: Ref<boolean> = ref(false)
const studentRef: Ref<Student | undefined> = ref()
const snackError = ref('')
const snackErrorDisplayed = ref(false)
const firstLetters = ref(false)

const roomRef = ref(props.room)

const roomStore = useRoomsStore()
const floorStore = useFloorsStore()

onMounted(() => {
  refresh()
})
onUpdated(() => {
  refresh()
})
function refresh() {
  roomRef.value = props.room
  studentRef.value = props.room.students?.find(s => s.isRoomReferent)
  updateSelectableStudents()
}

function archive() {
  roomRef.value.isArchived = true
  update()
}

function unarchive() {
  roomRef.value.isArchived = false
  update()
}

function remove() {
  emits('remove', roomRef.value)
}

function updateStudentRef(studentRef: Student | undefined) {
  props.room.students?.forEach(s => s.isRoomReferent = s.id == studentRef?.id)
}

function update() {
  // If watcher room => no students in this room, no student ref
  if (roomRef.value.isWatcherRoom) {
    roomRef.value.students = []
    updateStudentRef(undefined)
  }

  // check last added student can trully be added (not exceeding the nbStudents per room)
  if (roomRef.value.students.length > roomRef.value.nbStudents) {
    roomRef.value.students.splice(roomRef.value.nbStudents, 1)
    // display error message in snackbar
    snackError.value = `Pas plus de ${roomRef.value.nbStudents} élève dans cette chambre...`
    snackErrorDisplayed.value = true
  }

  // ensure not keeping a room studentRef who'se not in the room anymore
  if (studentRef.value && !roomRef.value.students.find(x => x.id == studentRef.value?.id)) {
    studentRef.value.isRoomReferent = false
    studentRef.value = undefined
  }
  // Ensure consistency of student ref and roomRef
  roomRef.value.students?.forEach(s => {
    // Addind a student who is referent in its room and no current referent for this room => new room ref
    if (!studentRef.value && s.isRoomReferent) {
      studentRef.value = s
    }
    // Already a student referent and adding another student who is refernet => keep room referent, the new student is not room referent anymore
    else if (studentRef.value && s.isRoomReferent && s.id != studentRef.value.id) {
      s.isRoomReferent = false
    }
    // Ensure consistency between roomRef.students and student.roomRef
    if (s.roomRef !== roomRef.value.id) {
      // Remove student from previous room in various stores...
      let previousRoom = roomStore.rooms.find(x => x.id === s.roomRef)
      if (previousRoom) {
        previousRoom.students = previousRoom.students.filter(x => x.id !== s.id)
      }
      previousRoom = floorStore.selected?.rooms.find(x => x.id === s.roomRef)
      if (previousRoom) {
        previousRoom.students = previousRoom.students.filter(x => x.id !== s.id)
      }
      // then associate this room to the student
      s.roomRef = roomRef.value.id
    }
  })

  // Clean students not part of the room anymore
  props.allStudents.forEach(student => {
    if (roomRef.value.students.indexOf(student) === -1 && student.roomRef === roomRef.value.id) {
      student.roomRef = ""
    }
  })

  // inform parent to update the room in the store
  emits('update', roomRef.value)

  updateSelectableStudents()
}
function clickedWithBeds(value: boolean | null) {
  roomRef.value.nbStudents = value ? 1 : 0
  update()
}

const selectableStudents: Ref<Array<Student>> = ref([])
function updateSelectableStudents() {
  selectableStudents.value = props.allStudents
    .filter(x => roomRef.value.roomGender ? x.gender == roomRef.value.roomGender : true)
    .sort((a, b) => {
      let result = (a.lastname + ' ' + a.firstname).localeCompare(b.lastname + ' ' + b.firstname)
      if (a.roomRef == roomRef.value.id && b.roomRef != roomRef.value.id) {
        return -1
      } else if (b.roomRef == roomRef.value.id && a.roomRef != roomRef.value.id) {
        return 1
      }
      else {
        return result
      }
    })
}

const removeRoomDialog = ref(false)
function resetDialog() {
  removeRoomDialog.value = false
}
</script>

<template>
  <v-form v-if="roomRef" ref="form" v-model="valid" lazy-validation>
    <v-dialog>
      <template v-slot:activator="{ props: deleteDialog }">
        <v-container :style="roomRef.isArchived ? 'color: rgb(var(--v-theme-warning)) !important;' : ''">
          <v-col>
            <v-text-field required v-model="roomRef.shortName" label="Nom" @update:modelValue="update()"
              class="mr-2"></v-text-field>
            <v-text-field required v-model.number="roomRef.nbStudents" type="number" label="Nombre de lits"
              @update:modelValue="update()"></v-text-field>
            <v-row no-gutters justify="center">
              <v-switch class="ma-2" :model-value="roomRef.nbStudents > 0" color="red" label="Avec des lits"
                @update:modelValue="(value) => clickedWithBeds(value)"></v-switch>
              <v-switch class="ma-2" v-model="roomRef.isForReducedMobility" color="success" label="PMR"
                @update:modelValue="update()"></v-switch>
              <v-switch class="ma-2" v-model="roomRef.isWatcherRoom" color="red" label="Chambre Surveillant"
                @update:modelValue="update()"></v-switch>
              <v-switch class="ma-2" v-model="roomRef.isPairMaster" color="yellow" label="MAP"
                @update:modelValue="update()"></v-switch>
            </v-row>
            <!-- <v-textarea v-model="roomRef.roomComment" label="Commentaire libre sur la chambre"></v-textarea> -->

            <v-select :items="gendersItems" item-title="label" item-value="value"
              :model-value="gendersItems.find(x => x.value == roomRef.roomGender)" label="Type de chambre"
              @update:model-value="(gender: any) => {
                roomRef.roomGender = gender
                update()
              }"></v-select>

            <!-- Students roomRef -->
            <v-autocomplete v-if="!roomRef.isWatcherRoom && roomRef.nbStudents > 0" v-model="roomRef.students"
              @update:modelValue="update()" :item-value="item => item" :items="selectableStudents"
              :item-title="(item) => item.firstname + ' ' + item.lastname" item-props label="Elèves de la chambre"
              multiple chips closable-chips color="error" :custom-filter="(value: string, query: string, item?: any) => {
                if (firstLetters) {
                  return item?.raw.lastname.toLowerCase().startsWith(query.toLowerCase())
                }
                return item?.raw.lastname.toLowerCase().includes(query.toLowerCase()) || item?.raw.firstname.toLowerCase().includes(query.toLowerCase())
              }">
              <template v-slot:chip="{ props, item }">
                <v-chip v-bind="props" :color="item.raw.isRoomReferent ? 'primary' : ''"
                  :prepend-avatar="item.raw.picture" :text="item.raw.lastname + ' ' + item.raw.firstname"></v-chip>
              </template>

              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props" :active="roomRef.students?.find(x => x.id === item.raw.id) != null"
                  :prepend-avatar="item.raw.picture" :subtitle="item.raw.classname"
                  :title="item.raw.lastname + ' ' + item.raw.firstname">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-account"></v-icon>
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-row>
              <v-spacer></v-spacer>
              <v-switch v-model="firstLetters" label="Nom de famille commence par..." color="blue"></v-switch>
            </v-row>

            <v-combobox v-if="!roomRef.isWatcherRoom && roomRef.nbStudents > 0" label="Référent incendie de la chambre"
              :items="roomRef.students" :item-title="item => item?.lastname + ' ' + item?.firstname" clearable
              v-model="studentRef" @update:model-value="() => {
                updateStudentRef(studentRef)
                update()
              }"></v-combobox>

            <!-- TODO: Watcher roomRef: handle adding workers -->
          </v-col>

          <v-row no-gutters justify="end">
            <v-btn v-if="roomRef.nbStudents > 0"
              :to="'/buildings/' + buildingId + '/floors/' + roomRef.floorRef + '/rooms/details/' + roomRef.id"
              prepend-icon="mdi-bullhorn" class="ma-1">Appel</v-btn>
            <v-btn class="ma-1" @click="archive()" prepend-icon="mdi-delete"
              v-if="!roomRef.isArchived">Supprimer</v-btn>
            <v-btn class="ma-1" @click="unarchive()" prepend-icon="mdi-restore"
              v-if="roomRef.isArchived">Restaurer</v-btn>
            <v-btn class="ma-1" v-bind="deleteDialog" @click="removeRoomDialog = true" prepend-icon="mdi-delete-forever"
              v-if="roomRef.isArchived">Supprimer définitivement</v-btn>
          </v-row>
        </v-container>
      </template>

      <template v-slot:default="{ isActive }">
        <v-row justify="center">
          <!-- Definitively delete room modale -->
          <v-card style="width: 600px; max-width: 90%; max-height: calc(100vh - 40px); overflow-y: auto;"
            v-if="removeRoomDialog">
            <v-card-title>
              <v-row no-gutters>
                Supprimer définitivement
              </v-row>
            </v-card-title>
            <v-card-item>
              Etes-vous certain de vouloir supprimer définitivement la chambre <b>{{ roomRef.shortName }} ?</b><br />
              Cette action est irréversible.
            </v-card-item>
            <v-card-actions>
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-btn text="Fermer" @click="() => {
                  isActive.value = false
                  resetDialog()
                }"></v-btn>
                <v-btn text="Supprimer" @click="() => {
                  remove()
                  isActive.value = false
                  resetDialog()
                }"></v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-row>
      </template>
    </v-dialog>

    <v-snackbar v-model="snackErrorDisplayed" color="error" rounded="pill" :timeout="5000" elevation="24">
      {{ snackError }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="() => {
          snackErrorDisplayed = false
        }" icon="mdi-close">
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>