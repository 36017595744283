<script setup lang="ts">
import { ref, type PropType, onUpdated, onMounted } from 'vue'
import { Comment } from '@/stores/types/comment'
import { useProfileStore } from '@/stores/profile';
import axios from 'axios';
import { baseUrl } from '@/stores/baseurl';
import type dayjs from 'dayjs';

const props = defineProps({
  comment: { type: Object as PropType<Comment>, required: true },
  observationTitle: { type: String as PropType<string>, default: 'Observation' },
  editable: { type: Boolean as PropType<boolean>, default: false },
  currentDay: { type: Object as PropType<dayjs.Dayjs>, required: true },
})
const emit = defineEmits(['update', 'sign', 'previous', 'next'])

const profileStore = useProfileStore()

const commentTxt = ref(props.comment?.comment)
const signature = ref(props.comment?.signature)
const giphy = ref()
const gifLoadedForDate = ref()

onMounted(async () => {
  await refresh()
})

onUpdated(async () => {
  await refresh()
})

async function loadGif() {
  // load giphy of the day
  try {
    // do not load the gif multiple times
    if (gifLoadedForDate.value == props.currentDay.format("YYYY-MM-DD")) {
      return;
    }
    gifLoadedForDate.value = props.currentDay.format("YYYY-MM-DD")
    const result = await axios.get(`${baseUrl}/gif/daily/${props.currentDay.format("YYYY-MM-DD")}`)
    giphy.value = result.data
  } catch (error) {
    console.error(error)
  }
}

async function refresh() {
  commentTxt.value = props.comment?.comment
  signature.value = props.comment?.signature
  await loadGif()
}

function sign() {
  signature.value = profileStore.current?.lastname + ' ' + profileStore.current?.firstname
  emit('sign', signature)
}
</script>

<template>
  <v-card v-if="comment" class="my-2">
    <v-card-item>
      <v-label>{{ observationTitle }}</v-label>
      <v-textarea :disabled="!editable" v-model="commentTxt"
        @update:model-value="$emit('update', commentTxt)"></v-textarea>
    </v-card-item>
    <v-card-item>
      <v-col>
        <v-label>Signature</v-label>
        <v-row no-gutters align="center" justify="center">
          <v-btn v-if="editable && profileStore.current?.lastname" class="ma-1" variant="flat" @click="sign()"
            prepend-icon="mdi-draw">Signer</v-btn>
          <v-text-field v-model="signature" @update:model-value="emit('sign', signature)"></v-text-field>
        </v-row>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-label v-if="!profileStore.current?.lastname">Signature avec le bouton désactivée car vous n'avez pas
            renseigné votre prénom/nom dans votre <a href="/profile">Profil</a></v-label>
          <v-label>Si tu signes, le "Gif du jour" apparaîtra...</v-label>
        </v-row>

        <v-row no-gutters class="my-4" justify="center" v-if="giphy && signature">
          <v-img align="center" justify="center" :max-width="giphy.images.downsized.width"
            :max-height="giphy.images.downsized.height" :src="giphy.images.downsized.url"></v-img>
        </v-row>
      </v-col>
    </v-card-item>
  </v-card>
</template>
