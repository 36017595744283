<script setup lang="ts">
import { useAttendancesStore } from '@/stores/attendance'
import { useBuildingsStore } from '@/stores/buildings'
import { useCommentsStore } from '@/stores/comments'
import { useFloorsStore } from '@/stores/floors'
import { useProfileStore } from '@/stores/profile'
import type { AttendanceExportItem } from '@/stores/types/attendanceexportitem'
import type { AttendanceHistory } from '@/stores/types/attendancehistory'
import { attendanceSlotsNotWednesday, attendanceSlotsWednesday } from '@/stores/types/attendanceslot'
import { getAttendanceStatusLabel } from '@/stores/types/attendancestatus'
import dayjs from 'dayjs'
import type { Ref } from 'vue'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import '@/assets/table.css'
import type { Attendance } from '@/stores/types/attendance'

const props = defineProps({
  buildingId: { type: String, required: true },
  floorId: { type: String, required: true },
  fromDateStr: { type: String, required: false },
  toDateStr: { type: String, required: false },
})

const commentStore = useCommentsStore()
const attendanceStore = useAttendancesStore()
const buildingStore = useBuildingsStore()
const floorStore = useFloorsStore()
const profileStore = useProfileStore()

const route = useRoute()
const router = useRouter()

const fromDateMenu = ref(false)
const fromDateVal = ref()

const toDateMenu = ref(false)
const toDateVal = ref()

const historyId = ref()

let currentSortBy: Ref<'studentLastname' | 'classname' | 'roomName'> = ref("studentLastname")
let isAscending = ref(true)

const classnameFilter = ref()
const displaySlotDetails = ref(false)

const selectedItem: Ref<AttendanceHistory | undefined> = ref()

const items: Ref<Array<AttendanceExportItem>> = ref([])
const filteredItems: Ref<Array<AttendanceExportItem>> = ref([])
const availableClasses: Ref<Array<string>> = ref([])
const slotsPerDay: Ref<Map<string, Array<string>>> = ref(new Map())
const slotsArray: Ref<Array<string>> = ref([])

onMounted(async () => {
  await buildingStore.load(props.buildingId)
  await floorStore.load(props.floorId)

  // By default we display current week
  fromDateVal.value = props.fromDateStr ? dayjs(props.fromDateStr) : dayjs().startOf('week')
  toDateVal.value = props.toDateStr ? dayjs(props.toDateStr) : dayjs().endOf('week')
  historyId.value = route.query.historyId as string
  await load(true)
})

async function load(initial: boolean) {
  await loadAttendances()
  await loadComments()
  await loadHistoryItemsList()
  if (initial && historyId.value) {
    await displayItem(await attendanceStore.loadHistoryItem(
      props.buildingId,
      props.floorId,
      historyId.value
    ))
  } else {
    historyId.value = undefined
  }

  isAscending.value = false
  sortBy('studentLastname')

  // store in URL the from/to dates
  router.push({
    name: 'floor',
    params: {
      buildingId: props.buildingId,
      floorId: props.floorId,
      tabId: 'history',
    },
    query: {
      fromDateStr: fromDateVal.value.format('YYYY-MM-DD'),
      toDateStr: toDateVal.value.format('YYYY-MM-DD'),
      historyId: historyId.value
    }
  })
}

async function loadComments() {
  if (!fromDateVal.value || !toDateVal.value || !buildingStore.selected?.id || !floorStore.selected?.id) return
  await commentStore.loadFromDateToDate(
    props.buildingId,
    props.floorId,
    fromDateVal.value.format('YYYY-MM-DD'),
    toDateVal.value.format('YYYY-MM-DD')
  )
}

async function loadAttendances() {
  if (!fromDateVal.value || !toDateVal.value || !buildingStore.selected?.id || !floorStore.selected?.id) return
  await attendanceStore.loadFromDateToDate(
    props.buildingId,
    props.floorId,
    fromDateVal.value.format('YYYY-MM-DD'),
    toDateVal.value.format('YYYY-MM-DD')
  )

  const slotsMap = new Map()
  attendanceStore.attendanceExportItems.forEach((item: AttendanceExportItem) => {
    item.attendances.forEach((attendance: Attendance) => {
      slotsMap.set(attendance.date.format("YYYY-MM-DD"), attendance.date.day() === 3 ? attendanceSlotsWednesday : attendanceSlotsNotWednesday)
    })
  })
  slotsPerDay.value = slotsMap
  slotsArray.value = Array.from(slotsMap.values()).reduce((acc, val) => acc.concat(val), [])

  availableClasses.value = attendanceStore.attendanceExportItems.map(x => x.classname).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a.localeCompare(b))
  items.value = attendanceStore.attendanceExportItems
  filterUpdated()
}


function getStatusLabel(item: AttendanceExportItem, date: string, slotStartTime: string) {
  return getAttendanceStatusLabel(item.attendances.
    find(x => x.date.format("YYYY-MM-DD") === date)?.slots.
    find(x => x.startTime === slotStartTime)?.status)
}

function sortBy(kind: 'studentLastname' | 'classname' | 'roomName') {
  if (kind === currentSortBy.value) isAscending.value = !isAscending.value
  else {
    currentSortBy.value = kind
    isAscending.value = true
  }

  filteredItems.value.sort((a: AttendanceExportItem, b: AttendanceExportItem) => {
    return isAscending.value ? a[currentSortBy.value].localeCompare(b[currentSortBy.value]) : b[currentSortBy.value].localeCompare(a[currentSortBy.value])
  })
}

async function saveAttendancesExport() {
  if (!filteredItems.value || !buildingStore.selected || !floorStore.selected) return
  const currentAttendance: AttendanceHistory = await attendanceStore.saveExport(
    buildingStore.selected.id,
    floorStore.selected.id,
    fromDateVal.value,
    toDateVal.value,
    classnameFilter.value,
    displaySlotDetails.value,
    filteredItems.value,
    buildingStore.selected.shortName,
    floorStore.selected.shortName)

  // Store history Id to be able to download the associated history
  historyId.value = currentAttendance.id

  // Update history items' list
  await loadHistoryItemsList()
}

async function downloadHistoryPdf() {
  if (!historyId.value || !buildingStore.selected || !floorStore.selected) return
  await attendanceStore.downloadHistoryPdf(
    buildingStore.selected.id,
    floorStore.selected.id,
    historyId.value,
    buildingStore.selected.shortName,
    floorStore.selected.shortName,
    classnameFilter.value,
    fromDateVal.value,
    toDateVal.value,
  )
}

async function loadHistoryItemsList() {
  if (!buildingStore.selected || !floorStore.selected) return
  await attendanceStore.loadHistoryItemsList(buildingStore.selected.id, floorStore.selected.id)
}

async function displayItem(item: AttendanceHistory) {
  // configure dates according to selected item and load associated attendance and comments
  fromDateVal.value = dayjs(item.fromDate)
  toDateVal.value = dayjs(item.toDate)
  await loadAttendances()
  await loadComments()

  // Also configure filter and displayed columns
  classnameFilter.value = item.filteredClassname
  displaySlotDetails.value = item.detailsDisplayed

  // Store historyId to allow pdf download
  historyId.value = item.id

  // then load and configure for each item the comments
  item = await attendanceStore.loadHistoryItem(
    props.buildingId,
    props.floorId,
    historyId.value
  )
  items.value.forEach((attendance: AttendanceExportItem) => {
    attendance.comment = item.studentComments?.find(x => x.studentId === attendance.studentId)?.comment || ''
  })
  items.value = attendanceStore.attendanceExportItems
  filterUpdated()

  // Update URL
  router.push({
    name: 'floor',
    params: {
      buildingId: props.buildingId,
      floorId: props.floorId,
      tabId: 'exports',
    },
    query: {
      fromDateStr: fromDateVal.value.format('YYYY-MM-DD'),
      toDateStr: toDateVal.value.format('YYYY-MM-DD'),
      historyId: historyId.value
    }
  })
}

function filterUpdated() {
  filteredItems.value = items.value.filter(x => !classnameFilter.value || x.classname === classnameFilter.value)
}

</script>

<template>
  <v-card class="my-2">
    <v-card-title>
      <div>Choisir des dates</div>
    </v-card-title>
    <v-card-item>
      <v-row no-gutters justify="center">
        <v-menu v-model="fromDateMenu" :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-text-field style="max-width: 180px" color="primary" label="Du" prepend-inner-icon="mdi-calendar" readonly
              :model-value="fromDateVal?.format('DD/MM/YYYY')" :active="props.isActive" v-bind="props"
              class="mr-2"></v-text-field>
          </template>
          <template v-slot:default="">
            <v-date-picker locale="fr-fr" v-model="fromDateVal" @update:modelValue="() => {
              fromDateMenu = false
              load(false)
            }" color="primary" show-adjacent-months no-title></v-date-picker>
          </template>
        </v-menu>

        <v-menu v-model="toDateMenu" :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-text-field style="max-width: 180px" color="primary" :active="props.isActive" label="Au"
              prepend-inner-icon="mdi-calendar" readonly :model-value="toDateVal?.format('DD/MM/YYYY')"
              v-bind="props"></v-text-field>
          </template>
          <template v-slot:default="">
            <v-date-picker locale="fr-fr" v-model="toDateVal" @update:modelValue="() => {
              toDateMenu = false
              load(false)
            }" color="primary" show-adjacent-months></v-date-picker>
          </template>
        </v-menu>
        <v-btn icon="mdi-magnify" class="ma-2" @click="load(false)"></v-btn>
      </v-row>
    </v-card-item>

    <v-card-title>
      Ou choisir une période enregistrée
    </v-card-title>
    <v-card-item>
      <v-list>
        <v-dialog>
          <template v-slot:activator="{ props: deleteHistoryItemDialog }">
            <v-list-item v-for="item in attendanceStore.historyItems" @click="displayItem(item)" :key="item.id"
              rounded="shaped" color="primary">
              <v-list-item-title>
                Période du {{ dayjs(item.fromDate).format('dddd') }} {{ dayjs(item.fromDate).format('DD/MM/YYYY') }} au
                {{ dayjs(item.toDate).format('dddd') }} {{ dayjs(item.toDate).format('DD/MM/YYYY') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="item.filteredClassname">Filtré sur la classe <b>{{ item.filteredClassname }}</b> / </span>
                <span v-if="!item.filteredClassname">Toutes les classes / </span>
                Détail des jours <b>{{ item.detailsDisplayed ? 'activé' : 'désactivé' }}</b>
              </v-list-item-subtitle>
              <template v-slot:prepend>
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:append>
                <v-btn v-if="profileStore.current?.role === 'admin'" class="mr-1" icon="mdi-delete"
                  @click="selectedItem = item" v-bind="deleteHistoryItemDialog"></v-btn>
                <v-btn icon="mdi-download"
                  @click="attendanceStore.downloadHistoryPdf(buildingId, floorId, item.id, buildingStore.selected?.shortName || '', floorStore.selected?.shortName || '', item.filteredClassname, dayjs(item.fromDate), dayjs(item.toDate))"></v-btn>
              </template>
            </v-list-item>
          </template>

          <template v-slot:default="{ isActive }">
            <v-row justify="center">
              <v-card style="max-width: 500px" v-if="selectedItem" title="Confirmer la suppression">
                <v-card-text>
                  <p class="mb-2">
                    Etes-vous certain de vouloir supprimer l'enregistrement suivant ?
                  </p>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        Période du {{ dayjs(selectedItem.fromDate).format('dddd') }} {{
                          dayjs(selectedItem.fromDate).format('DD/MM/YYYY') }} au {{
                          dayjs(selectedItem.toDate).format('dddd') }} {{ dayjs(selectedItem.toDate).format('DD/MM/YYYY')
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="selectedItem.filteredClassname">Filtré sur la classe <b>{{
                          selectedItem.filteredClassname }}</b> / </span>
                        Détail des jours <b>{{ selectedItem.detailsDisplayed ? 'activé' : 'désactivé' }}</b>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-btn text="Annuler" @click="() => {
                      isActive.value = false
                      selectedItem = undefined
                    }"></v-btn>
                    <v-btn text="Supprimer" @click="attendanceStore.deleteHistoryItem(buildingId, floorId, selectedItem!!.id).then(() => {
                      isActive.value = false
                      selectedItem = undefined
                    })"></v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-row>
          </template>
        </v-dialog>
        <div v-if="attendanceStore.historyItems.length === 0">
          Aucune période enregistrée
        </div>
      </v-list>
    </v-card-item>
  </v-card>

  <div id="attendances">
    <v-card v-if="fromDateVal && toDateVal" class="my-2">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title>
            <template v-slot:default="{ expanded }">
              <v-row no-gutters>
                <v-col class="d-flex justify-start" cols="4">
                  <v-card-title>Observations du {{ fromDateVal.format('dddd') }} {{ fromDateVal.format('DD/MM/YYYY') }}
                    au {{ toDateVal.format('dddd') }} {{ toDateVal.format('DD/MM/YYYY') }}</v-card-title>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-card-text>
                <v-col>
                  <div v-for="comment in commentStore.weeklyComments" :key="comment.id">
                    <h4>{{ dayjs(comment.date).format('dddd') + ' ' + dayjs(comment.date).format('DD/MM/YYYY') }}</h4>
                    <p>{{ comment.comment }}</p>
                    <v-row no-gutters>
                      <v-spacer></v-spacer>
                      <v-label>{{ comment.signature }}</v-label>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                  </div>
                </v-col>
              </v-card-text>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card v-if="fromDateVal && toDateVal && items" class="my-2">
      <v-card-title>Appels du {{ fromDateVal.format('dddd') }} {{ fromDateVal.format('DD/MM/YYYY') }} au {{
        toDateVal.format('dddd') }} {{ toDateVal.format('DD/MM/YYYY') }}</v-card-title>

      <!-- Table Display configuration -->
      <v-card-item>
        <v-select v-model="classnameFilter" clearable :items="availableClasses" label="Filtrer sur la classe"
          class="mr-2" @update:model-value="filterUpdated()">
        </v-select>
        <v-switch v-model="displaySlotDetails" label="Afficher le détail des jours" color="primary"></v-switch>
      </v-card-item>

      <!-- Table -->
      <v-card-text>
        <v-table hover fixed-header>
          <thead>
            <tr>
              <th class="text-center" colspan="3">
                Elèves
              </th>
              <th class="text-center" v-for="[date, slots] in displaySlotDetails ? slotsPerDay : new Map()" :key="date"
                :colspan="slots.length">
                {{ dayjs(date).format('DD/MM/YYYY') }}<br />
                {{ dayjs(date).format('dddd') }}
              </th>
              <th class="text-center" colspan="2">
                Totaux<br />(hors mercredi)
              </th>
              <th>
              </th>
            </tr>
            <tr>
              <th class="text-left fixed-column sortable-header"
                :class="currentSortBy == 'studentLastname' ? 'font-weight-bold' : ''"
                @click="sortBy('studentLastname')">
                <v-row no-gutter justify="start" align="center" style="margin: 0">
                  <div style="min-width: 22px;">
                    <div>
                      <v-icon :style="currentSortBy == 'studentLastname' && !isAscending ? '' : 'opacity: 0.3'"
                        :color="currentSortBy == 'studentLastname' && !isAscending ? 'primary' : 'grey'">mdi-chevron-up</v-icon>
                    </div>
                    <div>
                      <v-icon :style="currentSortBy == 'studentLastname' && isAscending ? '' : 'opacity: 0.3'"
                        :color="currentSortBy == 'studentLastname' && isAscending ? 'primary' : 'grey'">mdi-chevron-down</v-icon>
                    </div>
                  </div>
                  <div>
                    Nom
                  </div>
                </v-row>
              </th>
              <th class="text-left sortable-header" style="min-width: 74px;"
                :class="currentSortBy == 'classname' ? 'font-weight-bold' : ''" @click="sortBy('classname')">
                <v-row no-gutter justify="start" align="center" style="margin: 0">
                  <div style="min-width: 22px;">
                    <div>
                      <v-icon :style="currentSortBy == 'classname' && !isAscending ? '' : 'opacity: 0.3'"
                        :color="currentSortBy == 'classname' && !isAscending ? 'primary' : 'grey'">mdi-chevron-up</v-icon>
                    </div>
                    <div>
                      <v-icon :style="currentSortBy == 'classname' && isAscending ? '' : 'opacity: 0.3'"
                        :color="currentSortBy == 'classname' && isAscending ? 'primary' : 'grey'">mdi-chevron-down</v-icon>
                    </div>
                  </div>
                  <div>
                    Classe
                  </div>
                </v-row>
              </th>
              <th class="text-left sortable-header" style="min-width: 90px;"
                :class="currentSortBy == 'roomName' ? 'font-weight-bold' : ''" @click="sortBy('roomName')">
                <v-row no-gutter justify="start" align="center" style="margin: 0">
                  <div style="min-width: 22px;">
                    <div>
                      <v-icon :style="currentSortBy == 'roomName' && !isAscending ? '' : 'opacity: 0.3'"
                        :color="currentSortBy == 'roomName' && !isAscending ? 'primary' : 'grey'">mdi-chevron-up</v-icon>
                    </div>
                    <div>
                      <v-icon :style="currentSortBy == 'roomName' && isAscending ? '' : 'opacity: 0.3'"
                        :color="currentSortBy == 'roomName' && isAscending ? 'primary' : 'grey'">mdi-chevron-down</v-icon>
                    </div>
                  </div>
                  <div>
                    Chambre
                  </div>
                </v-row>
              </th>
              <th class="text-left" v-for="slot in displaySlotDetails ? slotsArray : []" :key="slot">
                {{ slot }}
              </th>
              <th>
                Nb Etudes
              </th>
              <th>
                Nb Absences
              </th>
              <th style="min-width: 220px; text-align: center;">
                Commentaires
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredItems" :key="item.studentId">
              <td class="text-left text-blue fixed-column">
                {{ item.studentLastname }} {{ item.studentFirstname }}
              </td>
              <td>{{ item.classname }}</td>
              <td>{{ item.roomName }}</td>
              <template v-for="[date, slots] in displaySlotDetails ? slotsPerDay : new Map()" :key="date">
                <td v-for="slot in slots" :key="slot">
                  {{ getStatusLabel(item, date, slot) }}
                </td>
              </template>
              <td>
                {{ item.nbStudies }}/{{ item.nbTotalPossibleStudies }}
              </td>
              <td>
                {{ item.nbAbsences }}
              </td>
              <td>
                <!-- Reset history id when text get's changed to force save before download  -->
                <v-textarea @update:model-value="historyId = undefined" v-model="item.comment"
                  append-inner-icon="mdi-comment" auto-grow rows="1"></v-textarea>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
    </v-card>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="!historyId" @click="downloadHistoryPdf" color="primary" icon="mdi-printer"
        variant="flat"></v-btn>
      <v-btn @click="saveAttendancesExport" color="primary" icon="mdi-content-save" variant="flat"></v-btn>
    </v-card-actions>
  </div>
</template>
