import axios from 'axios'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { baseUrl } from './baseurl'
import { Building } from './types/building'
import { Floor } from './types/floor'
import { Comment } from './types/comment'
import { useBuildingsStore } from './buildings'
import { useFloorsStore } from './floors'

const urlComment = `${baseUrl}/buildings/:buildingId/floors/:floorId/comments/:date`
const urlCommentFromTo = `${baseUrl}/buildings/:buildingId/floors/:floorId/comments?fromDate=:fromDate&toDate=:toDate`

export const useCommentsStore = defineStore('comments', () => {
  const building: Ref<Building | undefined> = ref()
  const floor: Ref<Floor | undefined> = ref()
  const weeklyComments: Ref<Array<Comment>> = ref([])
  const comment: Ref<Comment | undefined> = ref()

  async function load(buildingId: string, floorId: string, date: string) {
    try {
      // load building
      if (building.value?.id != buildingId) {
        const buildingData = await useBuildingsStore().load(buildingId)
        if (!buildingData || !buildingData.id) {
          console.error(`No building found with id ${buildingId}`)
          return
        }
        building.value = buildingData
      }

      // load floor
      if (floor.value?.id !== floorId) {
        floor.value = undefined
        const localFloor = await useFloorsStore().load(floorId)
        if (!localFloor) {
          console.error(`No floor found with id ${floorId}`)
          return
        }
        floor.value = localFloor
      }

      // load comment or create a new one
      try {
        const commentData = await axios.get(
          `${urlComment.replace(':buildingId', building.value.id).replace(':floorId', floor.value.id).replace(':date', date)}`
        )

        if (commentData.data.id == '') {
          comment.value = new Comment(floorId, date)
        } else {
          comment.value = commentData.data
        }
      } catch (error) {
        // create new comment if none has been found
        if (axios.isAxiosError(error) && error.response?.status == 404) {
          comment.value = new Comment(floorId, date)
        }
        // otherwise, it should not happen, display an alert to debug !!!
        else {
          console.log(error)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function loadFromDateToDate(
    buildingId: string,
    floorId: string,
    fromDate: string,
    toDate: string
  ) {
    weeklyComments.value = []
    const commentsData = await axios.get(
      `${urlCommentFromTo.replace(':buildingId', buildingId).replace(':floorId', floorId).replace(':fromDate', fromDate).replace(':toDate', toDate)}`
    )
    weeklyComments.value = commentsData.data
  }

  async function update(buildingId: string) {
    if (!comment.value) {
      console.error('Cannot update an undefined Comment...')
      return
    }
    _update(buildingId)
  }

  async function _update(buildingId: string) {
    if (!comment.value) return
    try {
      await axios.put(
        `${urlComment.replace(':buildingId', buildingId).replace(':floorId', comment.value.floorRef).replace(':date', comment.value.date)}`,
        comment.value
      )
      if (comment.value.id == '') {
        await load(buildingId, comment.value.floorRef, comment.value.date)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return { building, floor, comment, weeklyComments, load, update, loadFromDateToDate }
})
