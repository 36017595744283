import axios from 'axios'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { baseUrl } from './baseurl'
import { Building } from './types/building'
import { Floor } from './types/floor'

const url = `${baseUrl}/buildings`

export const useBuildingsStore = defineStore('buildings', () => {
  const buildings: Ref<Array<Building>> = ref([])
  const newBuilding: Ref<Building> = ref(new Building())
  const selected: Ref<Building | undefined> = ref()

  async function all() {
    try {
      const data = await axios.get(`${url}`)
      buildings.value = data.data
      buildings.value.map(
        (x) =>
          (x.floors = (x.floors == null ? [] : x.floors)
            ?.sort((a, b) => (a.isArchived && !b.isArchived ? 1 : -1))
            .sort((a: Floor, b: Floor) => a.index - b.index))
      )
    } catch (error) {
      console.log(error)
    }
  }

  async function load(buildingId: string): Promise<Building | null> {
    try {
      // do not load if already loaded !
      if (selected.value && selected.value.id === buildingId) return selected.value

      selected.value = undefined
      const data = await axios.get(`${url}/${buildingId}`)
      selected.value = data.data as Building
      if (selected.value.floors == null) {
        selected.value.floors = []
      }
      return selected.value
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status == 404) {
        console.error(`Building with id ${buildingId} not found`, error)
      } else {
        console.log(error)
      }
      return null
    }
  }

  async function addNew(): Promise<Building | null> {
    try {
      newBuilding.value = new Building()
      const buildingCreatedResp = await axios.post(`${url}`, newBuilding.value)
      buildings.value = buildings.value.concat(buildingCreatedResp.data).sort((a, b) => {
        if (a.id === buildingCreatedResp.data.id) return -1
        if (b.id === buildingCreatedResp.data.id) return 1
        return a.shortName.localeCompare(b.shortName)
      })
      return buildingCreatedResp.data as Building
    } catch (error) {
      console.log(error)
      return null
    }
  }

  function resetNew() {
    newBuilding.value = new Building()
  }

  async function update(building: Building) {
    try {
      await axios.put(`${url}/${building.id}`, building)
      buildings.value = buildings.value.map((x) => (x.id == building.id ? building : x))
    } catch (error) {
      console.log(error)
    }
  }

  async function archive(building: Building) {
    try {
      building.isArchived = true
      await update(building)
    } catch (error) {
      console.log(error)
    }
  }

  async function unarchive(building: Building) {
    try {
      building.isArchived = false
      await update(building)
    } catch (error) {
      console.log(error)
    }
  }

  async function remove(building: Building) {
    try {
      await axios.delete(`${url}/${building.id}`)
      buildings.value = buildings.value.filter((x) => x.id != building.id)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    buildings,
    newBuilding,
    selected,
    all,
    load,
    addNew,
    resetNew,
    update,
    archive,
    unarchive,
    remove
  }
})
